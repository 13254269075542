<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Assign Buyer</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="my-4">
                    <v-col cols="12">
                        <v-combobox
                            v-model="user"
                            hide-details
                            label="Users"
                            prepend-icon="mdi-account"
                            :items="users"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="save"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'AssingBuyer',
    props: {
        requestId: String,
        users: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        user: undefined,
        rules: {
            required: v => !!v || 'The param is required',
        },
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async save() {
            try {
                this.loading = true
                await API.assignRequest(this.requestId, {
                    buyer: this.user.name == 'On Hold' ? '' : this.user.id,
                    status: this.user.name == 'On Hold' ? 'onHold' : 'pricing',
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
